<template>
  <div>
    <sb-render v-if="story && story.content" :item="story.content" />
  </div>
</template>

<script>
import StoryblokMixin from 'src/modules/vsf-storyblok-module/components/StoryblokMixin'
import Render from 'src/modules/vsf-storyblok-module/components/Render'

export default {
  mixins: [StoryblokMixin],
  components: {
    Render
  }
}
</script>
